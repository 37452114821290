<template>
  <div>
    <input type="text" placeholder="请输入您需要查询的内容" v-model="searchText">
    <ul>
      <li v-for="(item,index) in searchList" :key="index">{{item}}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'test',
  data () {
    return {
      searchText: '',
      list: []
    };
  },
  mounted () {
  },
  computed: {
    searchList () {
      if (this.searchText) {
        return this.list.filter((text) => {
          return text.match(this.searchText);
        });
      } else {
        return [];
      }
    }
  }
};
</script>

<style scoped>

</style>
